.body {
  padding-top: 60px;
  display: flex;
}

.body > div {
  margin: auto;
}

.body svg {
  fill: var(--color-primary);
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: var(--space-m);
  margin: auto;
  max-width: 720px;
  box-shadow: var(--elevation-medium);
}

.container > div {
  display: flex;
  margin: auto;
  gap: var(--space-m);
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.container p {
  font-size: 14px;
  color: var(--cool-gray-40);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-m);
  margin-bottom: var(--space-l);
}

.logo svg {
  width: 300px;
  height: auto;
}

.primaryActionButton {
  padding-bottom: var(--space-m);
  flex: 1;
  align-items: center;
  text-align: center;
  padding: var(--space-m);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.primaryActionButton p {
  margin-bottom: var(--space-m);
}

.primaryActionButton button {
  position: relative;
  bottom: 0;
}

.primaryActionButton svg {
  fill: var(--color-primary);
  width: 50px;
}

.headline {
  font-size: 18px;
  margin-top: var(--space-s);
  margin-bottom: var(--space-s);
}

.panelFooter {
  text-align: center;
  border-radius: 0 0 var(--border-radius-panel) var(--border-radius-panel);
  background: var(--cool-gray-95);
  padding: var(--space-l);
}
